import React from 'react';

const SoundCloud = () => {
  return (
    <div>
      <iframe 
        width="100%" 
        height="450" 
        scrolling="no" 
        frameBorder="no" 
        allow="autoplay" 
        src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/playlists/135912472&color=%23ff5500&auto_play=true&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true">
      </iframe>
      <div style={{ fontSize: "10px", color: "#cccccc", lineBreak: "anywhere", wordBreak: "normal", overflow: "hidden", whiteSpace: "nowrap", textOverflow: "ellipsis", fontFamily: "Interstate, Lucida Grande, Lucida Sans Unicode, Lucida Sans, Garuda, Verdana, Tahoma, sans-serif", fontWeight: 100 }}>
        <a href="https://soundcloud.com/ghawk1ns" title="Guy Hawkins" target="_blank" style={{ color: "#cccccc", textDecoration: "none" }}>Guy Hawkins</a> · 
        <a href="https://soundcloud.com/ghawk1ns/sets/homebrew" title="Homebrew" target="_blank" style={{ color: "#cccccc", textDecoration: "none" }}>Homebrew</a>
      </div>
    </div>
  );
}

export default SoundCloud;