import React from 'react';
import './App.css';
import { SoundCloud } from './components';


function App() {
  return (
    <div className="App">
      <header className="App-header">
        <p>
          Guy Hawkins Personal Site
        </p>
        <SoundCloud />
      </header>
    </div>
  );
}

export default App;
